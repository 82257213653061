import * as React from 'react';
import styled from 'styled-components';

import Image from '../components/image';
import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout path="/">
    <SEO
      title="Home"
      description="株式会社アールエムシーは、砂利、砂などの資材の採掘及び輸出入、販売及び仲介、運送のコンサルタント業務から広範囲にわたる建築・土木工事に至るまで幅広く事業を展開している企業です。"
    />
    <Wrapper>
      <Image filename="top-main.png" alt="かぎりある資源を有効的に。" />
    </Wrapper>
  </Layout>
);

const Wrapper = styled.div`
  margin-top: 25px;
`;

export default IndexPage;
